import React, { useEffect, useState, useContext } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
 
 
import './BookingForm.scss';
import useWindowSize from "utils/useWindowSize";
import moment from 'moment-timezone';
import CalendarDataContext from '../../../contexts/CalendarDataContext';
import axios from 'axios';
import { extendMoment } from 'moment-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
const momentRange = extendMoment(moment);


const BookingForm = ({ selectedDates, onDateChange, maxOccupancy, maxPets, currencySymbol, pageId, disableAvailability, propertyMaxRate, propertyMinRate }) => {
    const CalendarData = useContext(CalendarDataContext);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const [focusedInput, setFocusedInput] = useState(null); 
    const { startDate, endDate } = selectedDates;
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [pets, setPets] = useState(0);
    const [bookingData, setBookingData] = useState(null);
    const windowSize = useWindowSize();
    const [calendarCols, setCalendarCols] = useState(2);
    const [error, setError] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const [orientation, setOrientation] = useState('horizonatal')
    const [withFullScreenPortal, setWithFullScreenPortal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [reset, setReset] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(moment());

    useEffect(() => {
        if(isMobile){
            // document.body.style.overflow = isOpen ? 'hidden' : 'visible';
            // document.getElementsByTagName('html')[0].style.overflow = isOpen ? 'hidden' : 'visible';
        }
        if (isOpen) {
           // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [isOpen]); 

 

 

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        const searchParams = new URLSearchParams(currentUrl.search);

        const adults = searchParams.get("Adults");
        const children = searchParams.get("Children");
        const infants = searchParams.get("Infants");
        const checkIn = searchParams.get("CheckIn");
        const checkOut = searchParams.get("CheckOut");

 
        setAdults(adults || 2);
        setChildren(children || 0);
        setInfants(infants || 0);
     
        if (checkIn && checkOut) {
            console.log(checkIn, checkOut);
            onDateChange(moment.utc(checkIn, "DD/MM/YYYY"), moment.utc(checkOut, "DD/MM/YYYY"));
            setCurrentMonth(moment.utc(checkIn, "DD/MM/YYYY")); 
  
        }
        setTimeout(() => {
            setFocusedInput('startDate');
        }, 1000);
    }, []); 

    useEffect(() => {
        if (windowSize.width > 800) {
            setCalendarCols(2);
            setOrientation('horizontal');
            setWithFullScreenPortal(false);
            setIsMobile(false);
        }
    
        if (windowSize.width < 800) {
            setCalendarCols(1);
            setOrientation('vertical');
            //setOrientation('verticalScrollable');
            setWithFullScreenPortal(true);
            setIsMobile(true);
        }
    }, [windowSize.width]);

    useEffect(() => {
 
   
        const fetchBookingData = async () => {
            // Don't fetch booking data until dates and counts are selected
            if (startDate === null || endDate === null) {
                return;
            }
             
            // Helper function to format date
            const formatDate = (date) => {
                // If the date is a string in 'dd/mm/yyyy' format, return it as is.
                if (typeof date === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
                    return date;
                }

                // If it's a moment object, extract the date components and format them.
                const day = date.date();
                const month = date.month() + 1; // Moment months are 0-indexed
                const year = date.year();

                return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            };

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ipro/webmethod.ashx`, {
                    params: {
                        methodName: 'ipro.acc.booking.calc',
                        checkin: formatDate(startDate),
                        checkout: formatDate(endDate),
                        adults,
                        children,
                        infants,
                        pets,
                        propertyid: pageId,
                        propertylanguageId: 0,
                        sourceId: '',
                        currency: currencySymbol
                    }
                });

                if (response.data.Error) {
                    setError(response.data.Error);
                } else {
                    setBookingData(response.data);
                    setError(null); // clear any previous error
                }
            } catch (error) {
                console.error("Failed to fetch booking data:", error);
            }
        };

        fetchBookingData();
    }, [startDate, endDate, adults, children, infants, pets, currencySymbol])

    const calendarDataMap = new Map(CalendarData.map(item => {
        const dateKey = item.t.split(" ")[0];  // Extract the date part
        return [dateKey, item];
    }));

    const isBlocked = day => {
        const dayInUTC = day.clone(); // Clone the day instead of converting to UTC

        const startDateInUTC = startDate ? startDate.clone() : null; // Clone the startDate if it exists
        const dateString = dayInUTC.format('DD/MM/YYYY');
        const currentDateObject = calendarDataMap.get(dateString);

        // Block any day that is less than today
        if (day.isBefore(new Date(), 'day')) {
            return true;
        }

        // Block unavailable dates
        if (currentDateObject && !currentDateObject.e) {
            return true;
        }

        // Block dates before the selected start date
        if (startDateInUTC && dayInUTC.isBefore(startDateInUTC, 'day') && !endDate) {
            return true;
        }

        // Allow dates within the valid range when a start date is selected
        if (startDateInUTC && !endDate) {
            const minStayNumber = currentDateObject?.ms ? parseInt(currentDateObject.ms.replace('+', ''), 10) : 1;
            const potentialEndDate = startDateInUTC.clone().add(minStayNumber - 1, 'days');

            if (dayInUTC.isSameOrAfter(startDateInUTC, 'day') && dayInUTC.isSameOrBefore(potentialEndDate, 'day')) {
                return false; // Allow dates within the valid min stay range
            }
        }

        // Allow dates if they satisfy special booking conditions
        if (currentDateObject) {
            if (currentDateObject.c || currentDateObject.cs || currentDateObject.ce || currentDateObject.sb || currentDateObject.sbs || currentDateObject.sbe) {
                return false;
            }
        }

        // Block dates if no special condition is met and they are unavailable
        if (!currentDateObject || !currentDateObject.e) {
            return true;
        }

        // Allow dates that are changeover or special booking days
        return !(currentDateObject.cs || currentDateObject.sbs);
    };

    const hasBookedDateInRange = _.memoize((startDate, endDates) => {
        if (!startDate || !endDates) {
            // If either date is null, we cannot create a range
            return false;
        }

        // Ensure endDates is always an array
        if (!Array.isArray(endDates)) {
            endDates = [endDates];
        }

        // We only check against the first endDate
        const endDate = endDates[0];
        const range = startDate.isBefore(endDate, 'day') ? momentRange.range(startDate, endDate) : momentRange.range(endDate, startDate);
        return Array.from(range.by('days')).some(dateInRange => {
            const dateInRangeString = dateInRange.format('DD/MM/YYYY');
            const dateInRangeObject = CalendarData.find(({ t }) => t.startsWith(dateInRangeString));
            return dateInRangeObject ? !dateInRangeObject.e : false;
        });
    });

    const isWithinMinStay = (minStay, nightDifference) => {
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return false;
        }

        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return nightDifference + 1 >= minStayNumber; // Include the start date
            } else {
                const availableStays = minStay.split(',').map(Number);
                return availableStays.includes(nightDifference + 1); // Include the start date
            }
        }

        else if (typeof minStay === 'number') { // Check if it's a single number

            return nightDifference + 1 === minStay; // Include the start date
        }
        console.error('Invalid minStay value: ', minStay);
        return true;
    };

    const addMinStayToStartDate = (startDate, minStay) => {

        const date = moment(startDate);
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return null;
        }
        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return date.clone().add(minStayNumber, 'days');
            } else if (minStay.includes(',')) {
                const minStayNumbers = minStay.split(',').map(Number);
                return minStayNumbers.map(num => date.clone().add(num, 'days'));
            }
        } else if (typeof minStay === 'number') {
            return date.clone().add(minStay, 'days');
        }

        console.error('Invalid minStay value: ', minStay);
        return null;
    };

    // Add this new function to get the minStay for a given date
    const getMinStayForDate = (date) => {
        const dateString = date ? date.format('DD/MM/YYYY') : null;
        const dateObject = dateString ? CalendarData.find(({ t }) => t.startsWith(dateString)) : null;

        // Add a check for undefined or null values
        return dateObject && dateObject.ms ? dateObject.ms : '1+';
    };


    const hasSelectedBookedDateInRange = (day) => {
        let date = startDate || day;
        let minStay = getMinStayForDate(date);

        let potentialEndDate = addMinStayToStartDate(date, minStay);

        const satisfiesCondition = !hasBookedDateInRange(date, potentialEndDate);
        return !satisfiesCondition;
    }


    const renderDayContents = day => {
        if (disableAvailability) {
            return (
                <>
                    {day.format('D')}
                </>
            );
        }

        let classNames = '';

        const dateString = day.format('DD/MM/YYYY');
        const currentDateObject = CalendarData.find(({ t }) => t.startsWith(dateString));

        const startDateString = startDate ? startDate.format('DD/MM/YYYY') : null;
        const startDateObject = startDateString ? CalendarData.find(({ t }) => t.startsWith(startDateString)) : null;

        let tooltipText = '';

        const isSelectedDate = dateString === startDateString;
        const selectedBookedDateInRange = hasSelectedBookedDateInRange();


        if (currentDateObject) {

            if (selectedBookedDateInRange) {
                classNames += ' no-hover';
            }
            const newStartDate = moment(day);
            const potentialEndDate = addMinStayToStartDate(newStartDate, currentDateObject.ms);

            const satisfiesCondition = !hasBookedDateInRange(newStartDate, potentialEndDate);

            if (typeof currentDateObject.ms === 'string' && currentDateObject.e) {

                const minStayNumber = parseInt(currentDateObject.ms.replace('+', ''), 10);

                tooltipText = satisfiesCondition ? "" : `${minStayNumber} nights minimum`;
                if (!satisfiesCondition) {
                    setShowTooltip(true)
                }

            } else {
                if (currentDateObject.e) {
                    tooltipText = satisfiesCondition ? "" : "Nights minimum";
                }

            }

            if (!currentDateObject.c && !currentDateObject.sbs) {
                tooltipText = 'Not a changeover day';
            }else{ 
                tooltipText = '';
            }
            
            if (!currentDateObject.e) {
                tooltipText = 'Booked';
                classNames += ' booked';
            }
            if (currentDateObject.e && currentDateObject.cs || currentDateObject.sbs && satisfiesCondition) {
                //   tooltipText = 'Avaliable as a start day';
            }
        }

        if (currentDateObject?.sbs && !currentDateObject?.cs && startDate && !endDate && day.diff(startDate, 'days') === 7) {
            classNames += 'disabledDay ';
        }



        return (
            <div className={`${classNames} tw-relative`}
            // onMouseMove={handleMouseMove}
            // onMouseLeave={handleMouseLeave}
            //data-tip={tooltipText}
            >
                {day.format('D')}
                {(showTooltip && ((startDate && endDate && tooltipText.length > 0) || (!startDate && !endDate) && tooltipText && tooltipText.length > 0)) &&
                    <div className='calendar_tooltipText'
                        style={{
                            position: 'absolute',
                            left: `${cursorPos.x - 60}px`,
                            top: `${cursorPos.y + 25}px`
                        }}
                    >
                        {tooltipText}
                    </div>
                }
            </div>
        );
    };

    const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
        if (!newStartDate && !newEndDate && !startDate && !endDate) {
           console.log(focusedInput);
            
        }

        if (startDate && endDate) {
            onDateChange(newStartDate, null);
            setFocusedInput('endDate');
            return;
        }


        if (disableAvailability) {
            onDateChange(newStartDate, newEndDate);
            setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
            return;
        }

        let selectedBookedDateInRange = hasSelectedBookedDateInRange(newStartDate || newEndDate);
        if (selectedBookedDateInRange) {
            onDateChange(null, null);
            setFocusedInput('startDate');
            return;
        }


        if (startDate && newStartDate && endDate && newEndDate) {
            if (newStartDate.isAfter(startDate) && newStartDate.isBefore(endDate)) {
                onDateChange(newStartDate, null);
                setFocusedInput('startDate');
                return;
            }
            if (newStartDate.isBefore(startDate)) {
                onDateChange(newStartDate, null);
                setFocusedInput('startDate');
                return;
            }
            if (newEndDate.isAfter(endDate)) {
                onDateChange(newEndDate, null);
                setFocusedInput('startDate');
                return;
            }
        }


        onDateChange(newStartDate, newEndDate);
        setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
    };

 
    const handleClear = () => {
        onDateChange(null);
    };


    const handleClose = () => {
        setFocusedInput(false);
        setIsOpen(false);
    };

    const renderCalendarInfo = () => (
        <div className="md:tw-static tw-bottom-10 tw-w-full tw-pb-1 tw-bg-white">
            {/* <div className="tw-mx-5 tw-mb-2">
                <b>{getDuration()}</b>
            </div> */}
            {/* <div className="tw-mx-5 tw-mb-2">
                {(bookingData && bookingData.RenterTotal > 0 && startDate && endDate ) &&
                    <div className="tw-flex  tw-text-xl tw-font-bold">
                        <span>Total:</span>
                        <span>{bookingData.CurrencySymbol}{bookingData.RenterTotal}</span>
                    </div>
                }
            </div> */}

            <div className='tw-flex tw-mx-5  tw-gap-4'>
               
                {/* <button
                    onClick={handleClose}
                    className="tw-bg-primary  tw-text-white tw-font-bold tw-py-1 tw-px-3 tw-rounded">
                    {isMobile ? 'Save' : 'Close'}
                </button> */}
                <span
                    onClick={(e) => {
                        handleClear();
                    }}
                    className="tw-mb-4 tw-text-primary tw-border-b tw-border-primary tw-mt-2">
                    Clear
                </span>
            </div>
        </div>
    );

    const getDuration = () => {
        if (startDate && endDate) {
            const duration = endDate.diff(startDate, 'days');
            const formattedStartDate = startDate.format('MMM D, YYYY');
            const formattedEndDate = endDate.format('MMM D, YYYY');
            return `${formattedStartDate} - ${formattedEndDate} (${duration} nights)`;
        }
        return '';
    };
 

    return (
        <div className="tw-p-5">

            <h5 className="tw-text-center tw-text-primary tw-font-extralight tw-font-monserrat">
                {currencySymbol && propertyMinRate && propertyMaxRate ? (
                    <b>{currencySymbol}{propertyMinRate}-{currencySymbol}{propertyMaxRate} Per Week </b>
                ) : (
                    'Booking Form '
                )} 
            </h5>

            <div className="tw-mt-6">
                {[{ label: 'Adults', count: adults, setCount: setAdults, min: 1, max: maxOccupancy - children },
                { label: 'Children', count: children, setCount: setChildren, min: 0, max: maxOccupancy - adults },
                { label: 'Infants', count: infants, setCount: setInfants, min: 0, max: 20 },
                { label: 'Pets', count: pets, setCount: setPets, min: 0, max: maxPets }].map(({ label, count, setCount, min, max }, index) => (
                    (label !== 'Pets' || maxPets > 0) && (
                        <div key={index} className="tw-flex tw-justify-between tw-items-center tw-border-b">
                            <h6 className="tw-w-1/4 tw-font-light">{label}</h6>
                            <div className="tw-w-3/4 tw-flex tw-justify-end">
                                <button onClick={() => setCount(count > min ? parseInt(count) - 1 : min)} className="tw-flex tw-items-center tw-justify-center  tw-rounded-full tw-w-[40px] tw-h-[40px]">
                                    <svg width="10" height="2" viewBox="0 0 5 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.579391 0.818V0.0529999H4.94839V0.818H0.579391Z" fill="#C5A05F" />
                                    </svg>

                                </button>
                                <div className="tw-px-3 tw-py-2 tw-mx-2 tw-w-[40px] tw-text-center">{count}</div>
                                <button onClick={() => setCount(count < max ? parseInt(count) + 1 : max)} disabled={count >= max} className={`tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-[40px] tw-h-[40px] ${count >= max ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}`}>
                                    <svg width="14" height="14" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.77529 7.569V0.530999H4.54029V7.569H3.77529ZM0.545289 4.424V3.693H7.77029V4.424H0.545289Z" fill="#C5A05F" />
                                    </svg>

                                </button>
                            </div>
                        </div>
                    )
                ))}
            </div>

            <h6 className='tw-font-light tw-my-2'>Please select your dates </h6>

            <div className="date-picker-container" tyle={{ height: 500 }} >
                <div className="BookingForm">
                    <DayPickerRangeController
                        displayFormat="Do  MMM YYYY"
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        daySize={25}
                        numberOfMonths={'1'}
                        navPrev={<span class="calendar-arrows left-arrow"><FontAwesomeIcon icon={faAngleLeft} /></span>}
                        navNext={<span class="calendar-arrows right-arrow"><FontAwesomeIcon icon={faAngleRight} /></span>}
                        hideKeyboardShortcutsPanel={true}
                        showClearDates
                        block
                        customArrowIcon={' '}
                        renderDayContents={renderDayContents}
                        renderCalendarInfo={renderCalendarInfo}
                        isOutsideRange={isBlocked}
                        transitionDuration={0}
                        firstDayOfWeek={1}
                        verticalBorderSpacing={1}
                        initialVisibleMonth={() => currentMonth}
                    />
                </div>

            </div>

 

            {(error && startDate && endDate) && <p className="tw-text-red-500">{error}</p>}

            {(!error && startDate && endDate )&&
                <div>

                    {/* {bookingData && bookingData.RenterAmount > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Renter Amount:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.RenterAmount}</span>
                        </div>
                    } */}

                  
                    {(bookingData && bookingData.ItemisedExtras.length > 0) &&
                        <div className="test">
                            {bookingData.ItemisedExtras.map((extra, index) => (
                                <div key={index} className="tw-flex tw-justify-between">
                                    <span>{extra.Name}</span>
                                    <span>{bookingData.CurrencySymbol}{extra.Amount}</span>
                                </div>
                            ))}
                        </div>    
                    }

                    {bookingData && bookingData.Discount > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Discount:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.Discount}</span>
                        </div>
                    }


                    {/* {bookingData && bookingData.BaseAmount > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Base Amount:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.BaseAmount}</span>
                        </div>
                    } */}


   

                    {bookingData && bookingData.HolidayExtraCost > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Holiday Extra Cost:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.HolidayExtraCost}</span>
                        </div>
                    }

                    {bookingData && bookingData.ExtraTotal > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Extra Total:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.ExtraTotal}</span>
                        </div>
                    }

                    {bookingData && bookingData.RentalRatesTax > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Rental Rates Tax:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.RentalRatesTax}</span>
                        </div>
                    }

                    {bookingData && bookingData.BookingFee > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Booking Fee:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.BookingFee}</span>
                        </div>
                    }

                    {bookingData && bookingData.BreakagesDeposit > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Breakages Deposit:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.BreakagesDeposit}</span>
                        </div>
                    }

                    {bookingData && bookingData.InsuranceTotal > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Insurance Total:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.InsuranceTotal}</span>
                        </div>
                    }

                    {/* <div className="tw-border-t tw-border-gray-200 tw-h-full tw-mt-3 tw-mb-2"></div> */}

                    {bookingData && bookingData.RenterTotal > 0 &&
                        <div className="tw-flex tw-justify-between  tw-text-2xl tw-font-bold tw-mt-3">
                            <span>Price</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.RenterTotal}</span>
                        </div>
                    }

                    {bookingData && bookingData.PayableToday > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Payable Today:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.PayableToday}</span>
                        </div>
                    }
                </div>
            }

            <div class="info tw-border tw-border-primary tw-p-3 tw-my-3">
                <p className='tw-font-bold tw-mb-0'>Deposit: 30% Upon Booking</p>
                <p className='tw-font-bold tw-mb-0'>Balance: 70% due 8 weeks before arrival</p>
                </div>

            <div className="tw-mt-2">
                {!error && startDate && endDate &&
                    <a href={`?alttemplate=checkout_guest&t=acc&checkin=${startDate.format('DD/MM/YYYY')}&checkout=${endDate.format('DD/MM/YYYY')}&adults=${adults}&children=${children}&infants=${infants}&pets=${pets}&propertyId=${pageId}&propertylanguageId=0&sourceId=&propertyCurrencyId=`}
                        className="!tw-border-current tw-border tw-rounded-none tw-no-underline !tw-py-2 tw-rounded !tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200 tw-uppercase tw-font-montserrat hover:tw-bg-primary hover:tw-text-white hover:tw-border-primary">
                        Book
                    </a>
                }


                <a href={`?alttemplate=Enquiry&${startDate && endDate ? `checkin=${startDate.format('DD/MM/YYYY')}&checkout=${endDate.format('DD/MM/YYYY')}&` : ''}adults=${adults}&children=${children}&infants=${infants}&pets=${pets}&propertyId=${pageId}&propertylanguageId=0&sourceId=&propertyCurrencyId=`}
                    className="btn btn-primary !tw-text-white tw-no-underline !tw-py-2 tw-rounded !tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200 tw-uppercase tw-font-montserrat">
                    Enquire
                </a>
            </div>

        </div>
    );
};

export default BookingForm;