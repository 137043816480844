import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import Location from './Location/Location';
import LocationMobile from './Location/LocationMobile';
import DatePicker from './DatePicker/DatePicker';
import DatePickerMobile from './DatePicker/DatePickerMobile';
import Guests from './Guests/Guests';
import GuestsMobile from './Guests/GuestsMobile';
import Filters from './Filters/Filters';
import SkeletonSearchForm from '../SkeletonSearchForm/SkeletonSearchForm';
import './SearchForm.css';
import axios from 'axios';
import useGetUrlParams from 'utils/useGetUrlParams';
import moment from 'moment';
import { SiteSettingsContext, CurrencyContext } from 'contexts/SiteSettingsProvider';
import useWindowSize from "utils/useWindowSize";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import FiltersContext from 'contexts/FiltersContext';

let completedActions = {
  changelocation: false,
  changeadults: false,
  changechildren: false,
  changeinfants: false,
  changedates: false,
  changeattrs: false,
  //changepricemode: false,
  //changepricerangenightly: false,
  changepricerangeweekly: false,
  changecurrency: false,
  changeflexiblenights: false,
}

let hasLoaded = false;
let isCurrencySearch = false;

function reducer(state, action) {
  switch (action.type) {
    case 'changelocation':
      completedActions.changelocation = true; // action is done
      return { ...state, Location: action.Location };
    case 'changeadults':
      completedActions.changeadults = true; // action is done
      return { ...state, Adults: action.Adults };
    case 'changechildren':
      completedActions.changechildren = true; // action is done
      return { ...state, Children: action.Children };
    case 'changeinfants':
      completedActions.changeinfants = true; // action is done
      return { ...state, Infants: action.Infants }; 
    case 'changedates':
      completedActions.changedates = true; // action is done
      return { ...state, CheckIn: action.CheckIn, CheckOut: action.CheckOut };
    case 'changeattrs':
      completedActions.changeattrs = true; // action is done
      return { ...state, Attributes: action.Attributes };
    case 'changepricemode':
      completedActions.changepricemode = true; // action is done
      return { ...state, PriceFilterType: action.PriceFilterType }; 
    case 'changepricerangenightly':
      completedActions.changepricerangenightly = true; // action is done
      return { ...state, NightlyPriceRange: action.NightlyPriceRange   };     
    case 'changepricerangeweekly':
      completedActions.changepricerangeweekly = true; // action is done
      return { ...state, WeeklyPriceRange: action.WeeklyPriceRange  };       
    case 'changecurrency':
      completedActions.changecurrency = true;  
      return { ...state, Currency: action.Currency };    
    case 'changeflexiblenights':
      completedActions.changeflexiblenights = true;
      return { ...state, flexibleNights: action.flexibleNights };
    default:
      throw new Error();
  }
} 

let initialSearchParapms = {
  clearcache: true,
  Location: '',
  Adults: 1,
  Children: 0,
  Infants: 0,
  CheckIn: '',
  CheckOut: '',
  PriceFilterType: '',
  NightlyPriceRange: '',
  WeeklyPriceRange: '',
  Currency: '',
  flexibleNights: '',
}



 
const SearchForm = ({ startMobileSearchFromModal, pageID, keyID, Currency, transferAllData, view, liftUpScrollImageState, isLoading, loadError, settings, sendDataToParnet, initAttributes, changeAttributes=null, priceMode, nightRange, weekRange, flexibleDates }) => {
  const hasFilters = useContext(FiltersContext);
  if (view === undefined) {
    view = "horizontal";
  }
 
  const { width } = useWindowSize();

  const currency = useContext(CurrencyContext);
  const inputRef = useRef()
  const navigate = useNavigate();
  const [clearChildFiled, setClearChildFiled] = useState(0);
  const [showMobileForm, setShowMobileForm] = useState(false);
  const [disableSearch, setDisableSearch] = useState(false);
  const [enableDateFilter, setEnableDateFilter] = useState(false);
  const [enableFlexibleNightsFilter, setEnableFlexibleNightsFilter] = useState(false);
  const [enableGuestFilter, setEnableGuestFilter] = useState(false);
  const [enableLocationFilter, setEnableLocationFilter] = useState(false);
  const [enablePetsFilter, setEnablePetsFilter] = useState(false);
  const [enablePriceRangeSlider, setEnablePriceRangeSlider] = useState(false);
  const [priceRangeSliderSettings, setPriceRangeSliderSettings] = useState(false);
  const [enablePropertyReferenceName, setEnablePropertyReferenceName] = useState(false);
  const [flexibleNights, setFlexibleNights] = useState(false);
  const [searchFormId, setSearchFormId] = useState(false);
  const [searchInLocation, setSearchInLocation] = useState(false);
  const [searchResultsPageId, setSearchResultsPageId] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [enableImageScroll, setEnableImageScroll] = useState(''); 
  const [readyToSearch, setReadyToSearch] = useState(false);
   
  const [updatesWeeklyPriceRange, setUpdatesWeeklyPriceRange] = useState([]);
  const [openSection, setOpenSection] = useState(1);
  let location = useLocation();
 
  const [error, setError] = useState(null);

  

  let [isFilters, setEnabledFilters] = useState(false);
  let [locations, setLocations] = useState([]);
  
  let [attributes, setAttributes] = useState([]);
  let [searchParapms, dispatch] = useReducer(reducer, initialSearchParapms);
  let [pageId, setPageId] = useState();
  let [keyId, setKey] = useState();
  const [isValidForm, setIsValidForm] = useState(true);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const stickyDiv = document.getElementById('mobileForm');
   
    if (stickyDiv) {
       
      const handleScroll = () => {
 
        const position = window.pageYOffset;
        if (position > 185) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [loadingForm]);


 
 
  useEffect(() => {

    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const urlParams = {};
    for (const [key, value] of params) {
      urlParams[key] = value;
    }

    const isURLParams = Object.keys(urlParams).length > 0;

    // Updated condition for searchParamsNotDefaults
    let searchParamsNotDefaults =
      urlParams.Location !== ''
      || (urlParams.Location !== '' && urlParams.Adults !== "1")
      || (urlParams.Location !== '' && urlParams.Adults !== "1" && urlParams.CheckIn !== '')
      || (urlParams.Location !== '' && urlParams.Adults !== "1" && urlParams.CheckIn !== '' && urlParams.CheckOut !== '')
      || (urlParams.Location !== '' && urlParams.Adults !== "1" && urlParams.CheckIn !== '' && urlParams.CheckOut !== '' && urlParams.Children !== "0")
      || (urlParams.Location !== '' && urlParams.Adults !== "1" && urlParams.CheckIn !== '' && urlParams.CheckOut !== '' && urlParams.Children !== "0" && urlParams.Infants !== "0");

 
    if (searchParamsNotDefaults && isURLParams && (location.pathname === '/search' || location.pathname === '/search/' && readyToSearch)) {
      setTimeout(() => {
        transferAllData(urlParams);
      }, 1);
    } else {
      if (location.pathname === '/search' || location.pathname === '/search/' && readyToSearch) {
        startSearch();
      }
    }

  }, [readyToSearch]);
 
 
 
  let domain = process.env.REACT_APP_BASE_URL;
  let APIUrl = "apis/searchpage";

  useEffect(() => {
    
    if (enableImageScroll !== '' && window.location.pathname === 'search/') {
      liftUpScrollImageState(enableImageScroll);
    }
  }, [enableImageScroll]); 
 

  useEffect(() => {
    setPageId(pageID);
    setKey(keyID);
 
    if (settings) {
      
      setEnableImageScroll(settings?.EnableImageScroll);
      setEnableFlexibleNightsFilter(settings?.EnableFlexibleNightsFilter); 
      setEnableFlexibleNightsFilter(settings?.EnableFlexibleNightsFilter);
      setEnableGuestFilter(settings?.EnableGuestFilter);
      setEnableLocationFilter(settings?.EnableLocationFilter);
      setEnableDateFilter(settings?.EnableDateFilter);
      setEnablePriceRangeSlider(settings?.EnablePriceRangeSlider);
      setPriceRangeSliderSettings(settings?.PriceRangeSliderSettings);
      setEnablePetsFilter(settings?.EnablePetsFilter);
      setEnablePropertyReferenceName(settings?.EnablePropertyReferenceName);
      setFlexibleNights(settings?.FlexibleNights);
      setSearchFormId(settings?.SearchFormId);
      setSearchInLocation(settings?.SearchInLocation);
      setSearchResultsPageId(settings?.SearchResultsPageId);
      setEnabledFilters(settings?.EnableAttributesFilter);
      setLocations(settings?.Locations);
      setAttributes(settings?.AttibutesSettings);
      setLoadingForm(true);
      setDisableSearch(settings?.DisableSearch);
    } else {
      const fetchSettings = async () => {
        try {
          const response = await fetch(`${domain}${APIUrl}?pageid=${pageID}&key=${keyID}&clear=true`);

          if (response.ok) {
            const data = await response.json();
 
            setIsValidForm(data.Success);
            sendDataToParnet(data);
            setEnableImageScroll(data.Settings?.EnableImageScroll);
            setEnableFlexibleNightsFilter(data.Settings?.EnableFlexibleNightsFilter);
            setEnableGuestFilter(data.Settings?.EnableGuestFilter);
            setEnableLocationFilter(data.Settings?.EnableLocationFilter);
            setEnableDateFilter(data.Settings?.EnableDateFilter);
            setEnablePriceRangeSlider(data.Settings?.EnablePriceRangeSlider);
            setPriceRangeSliderSettings(data.Settings?.PriceRangeSliderSettings);
            setEnablePetsFilter(data.Settings?.EnablePetsFilter);
            setEnablePropertyReferenceName(data.Settings?.EnablePropertyReferenceName);
            setFlexibleNights(data.Settings?.FlexibleNights);
            setSearchFormId(data.Settings?.SearchFormId);
            setSearchInLocation(data.Settings?.SearchInLocation);
            setSearchResultsPageId(data.Settings?.SearchResultsPageId);
            setEnabledFilters(data.Settings?.EnableAttributesFilter);
            setLocations(data.Settings?.Locations);
            setAttributes(data.Settings?.AttibutesSettings);
            setLoadingForm(true);
            window.sessionStorage.setItem("pagesettings", JSON.stringify(data));
          } else {
            setError(`HTTP error: ${response.status}`);
            console.error(`HTTP error: ${response.status}`);
            loadError(response.status);
          }
        } catch (error) {
          setError('Error fetching data');
          console.error('Error fetching data:', error);
          loadError(error);
        }
      };

      fetchSettings();
    }
  }, [pageID]);

  useEffect(() => {
 
    if (currency && currency[0] !== '') {
      dispatch({ type: "changecurrency", Currency: currency[0] });
      isCurrencySearch = true;
    }
  }, []);  

  useEffect(() => {
    if (currency && currency[0] !== ''){
      dispatch({ type: "changecurrency", Currency: currency[0] });
      isCurrencySearch = true;
    }

    if (currency && currency[0] === '') {
      if (Currency){
        const firstKey = Object.keys(Currency)[0];
  
        dispatch({ type: "changecurrency", Currency: firstKey });
        isCurrencySearch = true;           
      }
    }

  }, [currency]);  

  useEffect(() => { 
  
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const urlParams = {};
    for (const [key, value] of params) {
      urlParams[key] = value;
    }
    const isURLParams = Object.keys(urlParams).length > 0;

    
    //No params and we are on the search page - use initial params 
    if (!isURLParams && (location.pathname === '/search' || location.pathname === '/search/')){
      setTimeout(() => {
       transferAllData(initialSearchParapms);
        startSearch();
      }, 1);
    }
   
 
  }, [location]); 

  useEffect(() => {

    hasLoaded = true;

  }, []); 

   useEffect(() => {

     const allParametersCame = Object.values(completedActions).every(
       value => value === true
     );

     if (allParametersCame && hasLoaded){
       setReadyToSearch(true);
     }
 
     if (allParametersCame && hasLoaded && isCurrencySearch && (window.location.pathname === '/search' || window.location.pathname === '/search/') && transferAllData){
       isCurrencySearch = false;
     }
 
     if (allParametersCame && hasLoaded && (window.location.pathname === '/search' || window.location.pathname === '/search/') && transferAllData) {
       hasLoaded = false;
     }

   },[searchParapms]); 

  function transferLocation(values){
    
    let valueString = '';
    if (Array.isArray(values) && values.length > 0) {
      valueString = values.map(({ value }) => value).join(',');
      dispatch({ type: "changelocation", Location: valueString });
    } 
    
    if (typeof values === "string"){
      
      if (values.length > 0){
        dispatch({ type: "changelocation", Location: values });
      }
      if (values.length === 0){
        dispatch({ type: "changelocation", Location: '' });
      }
    }
    
 
  }

  const transferAdults = (adults) => {
    
    if (adults === '' || adults === null || adults === undefined) {
      dispatch({ type: "changeadults", Adults: 1 });
      return;
    }
    dispatch({ type: "changeadults", Adults: adults });
   // setSearchParams({ ...searchParapms, Adults: adults });
  }

  const transferChildren = (children) => {
    
    if (children === '' || children === null || children === undefined) {
      dispatch({ type: "changechildren", Children: 0 });
      return;
    }
    dispatch({ type: "changechildren", Children: children });
  }

  const transferInfants = (infants) => {
 
    if (infants === '' || infants === null || infants === undefined) {
      dispatch({ type: "changeinfants", Infants: 0 });
      return;
    }
    dispatch({ type: "changeinfants", Infants: infants });
    
  }
 
  const transferDates = (val) => {
 
    
      if (val[0] != null && val[1] != null) {
 
        let checkIn = moment(val[0]._d).format('YYYY-MM-DD');
        let checkOut = moment(val[1]._d).format('YYYY-MM-DD');
       
        //setSearchParams({ ...searchParapms, CheckIn: checkIn, CheckOut: checkOut });
        dispatch({ type: "changedates", CheckIn: checkIn, CheckOut: checkOut });
      } else {
        dispatch({ type: "changedates", CheckIn: '', CheckOut: '' });
      }
  }

 

  // useEffect(() => {
  //   transferAttrsOnLoad(initAttributes);
  // }, [initAttributes]);
 
  const transferAttrsOnLoad = (attrs) => {
    //console.log(attrs)
    if (Array.isArray(attrs)) {
      dispatch({ type: "changeattrs", Attributes: attrs.toString() });
    }
    if (attrs !== "") {
      dispatch({ type: "changeattrs", Attributes: attrs });
    }
    if (attrs === "" || attrs === null || attrs === undefined) {
      dispatch({ type: "changeattrs", Attributes: '' });
    }

  }

  useEffect(() => { 
     
    transferAttrsOnChange(changeAttributes) 
    
  }, [changeAttributes]);

  const transferAttrsOnChange = (attrs) => {
    
    if (attrs !== "") {
      dispatch({ type: "changeattrs", Attributes: attrs });
    }
    if (attrs === "" || attrs === null || attrs === undefined) {
      dispatch({ type: "changeattrs", Attributes: '' });
    } 
 
  }

  useEffect(() => {
    
    if (changeAttributes && readyToSearch || changeAttributes === ''){
      
       startSearch(); 
    }
  }, [searchParapms.Attributes ]);

  useEffect(() => {
 
    if (weekRange && readyToSearch || weekRange === '') {

      startSearch();
    }
 
  }, [searchParapms.WeeklyPriceRange]);

 
  useEffect(() => {
    if (flexibleDates && readyToSearch || flexibleDates === '') {

      startSearch();
    }

    

  }, [searchParapms.flexibleNights]);
  

  useEffect(() => {
    //console.log(startMobileSearchFromModal);
    
     //startSearch();  
  }, [startMobileSearchFromModal]);

 
  // useEffect(() => {
    
  //   if (upatedAttributes && upatedAttributes.length > 0){
  //     startSearch();  
  //   }
  //   if (upatedAttributes === ''){
  //     startSearch();
  //   }
     
  // }, [upatedAttributes]); 

  useEffect(() => {transferPriceMode(priceMode)}, [priceMode]);

  const transferPriceMode = (mode) => {
    
     if(mode === 'Weekly'){
       dispatch({ type: "changepricemode", PriceFilterType: 'Weekly' });
     }
     if (mode === 'Nightly') {
      dispatch({ type: "changepricemode", PriceFilterType: 'Nightly' });
     }
    if (mode === "" || mode === null || mode === undefined) {
      dispatch({ type: "changepricemode", PriceFilterType: 'Weekly' });
    }
  }

  useEffect(() => {transNightRange(nightRange)}, [nightRange]);

  const transNightRange = (range) => {
    //console.log(range);
    if (range === "" || range === null || range === undefined) {
 
      dispatch({ type: "changepricerangenightly", NightlyPriceRange: '' });
    }
    if (range !== "") {
      
      dispatch({ type: "changepricerangenightly", NightlyPriceRange: range, WeeklyPriceRange: '' });
    }
  }

  useEffect(() => { 
     transWeekRange(weekRange);
 
 
    }, [weekRange]);

  useEffect(() => {
    if (flexibleDates){
      dispatch({ type: "changeflexiblenights", flexibleNights: flexibleDates });
      setFlexibleNights(flexibleDates);
 
    }

  }, [flexibleDates]);


  // useEffect(() => {
  //   if (flexibleNights) {
  //     startSearch();
  //   }
  // }, [flexibleNights]);
 
  // useEffect(() => {
  //   if (updatesWeeklyPriceRange) {
  //     if (updatesWeeklyPriceRange.length > 0){
  //        startSearch();
  //     }
      
  //   }
  // }, [updatesWeeklyPriceRange]);

  const transWeekRange = (range) => {
    //console.log(range);
    setUpdatesWeeklyPriceRange(range);
    if (range === "" || range === null || range === undefined) {
      dispatch({ type: "changepricerangeweekly", WeeklyPriceRange: '' });
    }
    if (range !== "") {
      dispatch({ type: "changepricerangeweekly", WeeklyPriceRange: range, NightlyPriceRange: '' });
    }
  }

  const startSearch = () => {
    // Update the URL parameters irrespective of the current path.
    

    // If you're on the `/search` path after updating the parameters.
    if (window.location.pathname === '/search' || window.location.pathname === '/search/') {
      // If transferAllData function exists, call it.
      if (transferAllData && readyToSearch) {
        
        transferAllData(searchParapms);
      }
    }
    if (window.location.pathname !== '/search' && window.location.pathname !== '/search/') {
      navigate("/search/" + '?' + new URLSearchParams(searchParapms).toString());
    }
  }

  function startMobileSearch(e){
    if (showMobileForm && width>window.lg) {
      startSearch();
      window.scrollTo(0, 0)
    }

    if (showMobileForm && width < window.lg) {
      var SortPanelElement = document.getElementById("SortPanel");

      if (SortPanelElement) {  // Check if the element exists
        var SortPanel = SortPanelElement.offsetTop;
        window.scrollTo({ top: SortPanel, behavior: 'smooth' });
        
      } else {
        console.warn("Element with ID 'SortPanel' not found.");
      }
      startSearch();
    }
 
    setShowMobileForm(!showMobileForm);
  }

  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (showMobileForm) {
      bodyClassList.add('SearchFormOpened');  // Add the class when showMobileForm is true
    } else {
      bodyClassList.remove('SearchFormOpened');  // Remove the class when showMobileForm is false
    }

    // Cleanup: Ensure that the class is removed when the component is unmounted
    return () => {
      bodyClassList.remove('SearchFormOpened');
    };
  }, [showMobileForm]);

  function toogleMobileSearchForm(e) {
    setShowMobileForm(prev => !prev);
  }

  function clearAllData(){
    setClearChildFiled(Math.random() * (2 - 1) + 2);
  }

  
  if (disableSearch){
    return null;
  }

  const transferFlexibility = (val) => {
    dispatch({ type: "changeflexiblenights", flexibleNights: val });
    setFlexibleNights(val);
  }

 
  const handleAccordionChange = (currentSection) => {
    if (currentSection === 1) {
      setOpenSection(openSection === 1 ? null : 1);
    } else if (currentSection === 2) {
      setOpenSection(openSection === 2 ? 3 : 2);
    } else if (currentSection === 3) {
      setOpenSection(openSection === 3 ? 2 : 3);
    }
  };
 
  if (width < window.lg) {
    return(
      <>  
 
      
        {loadingForm && isValidForm ? <div id="mobileForm" 
          style={{
            position: isFixed ? 'fixed' : 'relative',
            top: '0',
            backgroundColor: 'white',
            margin: isFixed ? '0' : undefined
          }}
         className="tw-w-auto tw-sticky tw-top-0 tw-z-[9] mobile-search-start-button tw-m-3 tw-mb-3 tw-bg-white tw-drop-shadow-lg tw-w-full tw-right-0 tw-left-0 tw-text-center"  >
          <div className=" ">
            <div className="tw-min-w-[300px] tw-p-3 tw-relative tw-z-70 tw-bg-primary tw-text-white tw-uppercase" onClick={toogleMobileSearchForm}>
              <span>Refine my search results</span>
              <svg className="svgFilters" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg>
            </div>
          </div>
        </div> : ""}
        <div className={showMobileForm ? 'mobileForm tw-top-0 tw-left-0 tw-fixed tw-bg-white tw-w-full tw-h-full tw-z-[999] tw-p-3 ' : 'tw-hidden' }    >

            <div className="tw-absolute tw-left-0 tw-top-0 tw-w-full shadow-sm tw-p-3 mobile-search-form-header">
              <>
                <button type="button" className="btn-close" onClick={toogleMobileSearchForm}></button>
              </>
            </div>

            <div className="tw-mt-[50px] tw-mb-[50px]">
              <div className={view + " search-from tw-relative "}>
              <div className="serch-form-field tw-bg-white location" >
                 
                   <LocationMobile 
                       onToggle={() => handleAccordionChange(1)}
                      isOpen={openSection === 1}
                      pageID={pageID} 
                      clearField={clearChildFiled} 
                      locationList={locations} 
                      changeLocation={transferLocation} 
                      enableLocationFilter={enableLocationFilter}
                      settings={settings} 
                    />
                </div>
                <div className="serch-form-field tw-bg-white dates tw-relative tw-z-20">
                  <DatePickerMobile 
                      isOpen={openSection === 2}
                      onToggle={() => handleAccordionChange(2)}
                      clearField={clearChildFiled} 
                      changeDates={transferDates} 
                      enableDateFilter={enableDateFilter} 
                      onChangeFlexibility={transferFlexibility}
                      enableFlexibleNightsFilter={enableFlexibleNightsFilter}
                    />
                </div>
                <div className="serch-form-field tw-bg-white guests tw-relative tw-z-10">
                  <GuestsMobile 
                      isOpen={openSection === 3}
                      onToggle={() => handleAccordionChange(3)}
                      clearField={clearChildFiled} 
                      changeAdults={transferAdults} 
                      changChildren={transferChildren} 
                      changInfants={transferInfants} 
                      enableGuestFilter={enableGuestFilter} 
                  />
                </div>

              </div>
            </div>

            <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-full shadow tw-p-3 mobile-search-form-footer tw-flex tw-justify-between">
              <button type="button" className="btn btn-link tw-text-black" onClick={clearAllData}>Clear All</button>
              <button type="button" className="btn btn-primary text-white" onClick={startMobileSearch}>Search</button> 
            </div>
        </div>
      </>
    );
  }

  if (width > window.lg){
    return (
      <>
        {isValidForm && 
          <>
          {loadingForm ?
            <div className="loading tw-sticky tw-top-0 tw-bg-white tw-z-20 search-from-wrapper">
              <div className={view + " search-from tw-relative "}>
                <div className="serch-form-field tw-bg-white location  ">
                  <Location pageID={pageID} locationList={locations} changeLocation={transferLocation} enableLocationFilter={enableLocationFilter} settings={settings}/>
                </div>
                <div className="serch-form-field tw-bg-white dates">
                  <DatePicker
                     changeDates={transferDates} 
                     enableDateFilter={enableDateFilter}
                     enableFlexibleNightsFilter={enableFlexibleNightsFilter}
                     onChangeFlexibility={transferFlexibility}
                   />
                </div>
                <div className="serch-form-field tw-bg-white guests">
                  <Guests changeAdults={transferAdults} changChildren={transferChildren} changInfants={transferInfants} enableGuestFilter={enableGuestFilter} settings={settings}/>
                </div>
                 
                <div className="serch-form-field tw-bg-white search-btn">
                  <button className="lg:tw-w-[280px] search-form-btn field tw-bg-primary tw-text-white tw-uppercase" onClick={() => { if (!isLoading) { startSearch(); } }} disabled={isLoading}>

                    <span className='tw-px-8'><FontAwesomeIcon icon={faMagnifyingGlass} size="lg" /> Search</span>
                    {isLoading && <Spinner animation="grow" size="sm" className="tw-ml-4 tw-relative tw-top-[2px]" variant="primary" />}
                  </button>
                </div>
              </div>
            </div> : <SkeletonSearchForm />
          }
          </>
        }



      </>
    );
  }

};

 

export default SearchForm;
